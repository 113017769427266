<template>
    <picture class="responsivePicture" v-if="image && image.url" ref="picture">
        <img 
            :fetchpriority="lazyload === false ? 'high' : undefined"

            :class="[{ lazy: !loaded }, imageClass]" 
            :src="inview ? image.url : undefined" 
            :data-src="image.url" 
            
            :height="image.height" 
            :width="image.width" 
            :alt="image.title" 
            @load="imageLoaded">
    </picture>
</template>

<script lang="ts" setup>
interface Props {
    image: {
        url: string;
        height?: number;
        width?: number;
        title?: string;
    };
    lazyload?: boolean;
    imageClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
    lazyload: true
})

const picture = ref()
const inview = ref(props.lazyload === false ? true : false)
const loaded = ref(!props.lazyload)

useIntersectionObserver(picture,
    ([{ isIntersecting }]) => {
        if (!inview.value && isIntersecting) inview.value = true
    }
)

const imageLoaded = () => {
    loaded.value = true
}
onMounted(() => {
    if (props.lazyload === false) loaded.value = true
})
</script>

<style lang="scss">
.responsivePicture {
    display: block;
    height: 100%;
    width: 100%;

    img {
        transition: opacity 300ms 0ms ease-in-out;

        &.lazy {
            opacity: 0;
        }
    }
}
</style>