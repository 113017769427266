<template>
    <section class="moduloCarousel">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="controls" v-if="shouldShowSwiper">
                <NButton size="medium" secondary circle :disabled="disablePrev" @click="swipePrev" tabindex="-1" :aria-label="$t('indietro')">
                    <template #icon>
                        <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowLeftRound"></NIcon>
                    </template>
                </NButton>
                <NButton size="medium" secondary circle :disabled="disableNext" @click="swipeNext" tabindex="-1" :aria-label="$t('avanti')">
                    <template #icon>
                        <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound"></NIcon>
                    </template>
                </NButton>
            </div>
        </div>
        <div :class="['cardsZone']" v-if="cards && cards.length > 0">
            <div :class="['swiperZone', { offset: !offsetMounted }]" v-if="shouldShowSwiper">
                <swiper-container slides-per-view="auto" :slides-per-group="1" :grab-cursor="true" :slides-offset-before="swiperOffset" :slides-offset-after="swiperOffset" ref="swiper">
                    <template v-for="card in cards">
                        <swiper-slide class="cardCont">
                            <CardPost v-if="modulo.contenuto == 'manuale' && card.item.__typename == 'link_social'" :cover="card.item.cover" :titolo="card.item.translations?.[0]?.titolo" :tipologia="card.item.tipologia_social" :link="card.item.link" />
                            <CardPost v-else-if="modulo.contenuto == 'juicer'" :externalImage="card.cover" :titolo="card.titolo" :tipologia="card.tipologia" :link="card.link" />
                        </swiper-slide>
                    </template>
                </swiper-container>
            </div>
            <div class="inlineCards" v-else>
                <div class="offset"></div>
                <template v-for="card in cards">
                    <div class="cardCont">
                        <CardPost v-if="modulo.contenuto == 'manuale' && card.item.__typename == 'link_social'" :cover="card.item.cover" :titolo="card.item.translations?.[0]?.titolo" :tipologia="card.item.tipologia_social" :link="card.item.link" />
                        <CardPost v-else-if="modulo.contenuto == 'juicer'" :externalImage="card.cover" :titolo="card.titolo" :tipologia="card.tipologia" :link="card.link" />
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { NButton, NIcon } from 'naive-ui'
import { KeyboardArrowLeftRound, KeyboardArrowRightRound } from '@vicons/material'

interface Props {
    modulo: any;
}
const props = defineProps<Props>()
const { getJuicerPosts } = useSocial()

const offsetMounted = ref(false)
const disablePrev = ref(true)
const disableNext = ref(false)

const { width: windowWidth } = useWindowSize({ includeScrollbar: false })
const swiper = ref()

const { data: generatedCards } = props.modulo.contenuto == 'juicer' && props.modulo.juicer_id ? useLazyAsyncData(`juicer-${props.modulo.juicer_id}`, () => getJuicerPosts(props.modulo.juicer_id)) : { data: null }

const cards = computed(() => {
    if (props.modulo.contenuto == 'juicer' && generatedCards && generatedCards.value) return generatedCards.value
    else if (props.modulo.contenuto == 'manuale') return props.modulo.card
    else return []
})

onMounted(() => {
    offsetMounted.value = true
})

const swiperOffset = computed(() => {
    if (windowWidth.value && isFinite(windowWidth.value) && offsetMounted.value === true) {
        if (windowWidth.value - 1220 > 0) return (windowWidth.value - 1200) / 2
        else return 20
    } else return 0
})
const shouldShowSwiper = computed(() => {
    if (cards.value && cards.value.length > 0 && windowWidth.value && isFinite(windowWidth.value) && offsetMounted.value === true) {
        if (cards.value.length * 330 > windowWidth.value - swiperOffset.value) return true
        else return false
    } else return false
})

const swipeNext = () => {
    if (swiper.value.swiper) {
        swiper.value.swiper.slideNext()
        disablePrev.value = swiper.value.swiper.isBeginning
        disableNext.value = swiper.value.swiper.isEnd
    }
}
const swipePrev = () => {
    if (swiper.value.swiper) {
        swiper.value.swiper.slidePrev()
        disablePrev.value = swiper.value.swiper.isBeginning
        disableNext.value = swiper.value.swiper.isEnd
    }
}
</script>


<style lang="scss" scoped>
.moduloCarousel {
    .cont {
        display: flex;
        flex-direction: row;
        gap: var(--margin-default);
        justify-content: space-between;

        padding: 0 var(--margin-page);
        min-height: 40px;
    }

    .controls {
        display: flex;
        flex-direction: row;
        gap: var(--margin-gap);
    }

    .cardsZone {
        margin-top: var(--article-gap-inner);
        height: 400px;

        .swiperZone {
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
            height: 400px;

            &.offset {
                margin-left: calc((100% - 1200px) / 2);
            }
        }

        .inlineCards {
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
            white-space: nowrap;

            .offset {
                display: inline-block;
                width: calc((100% - 1200px) / 2);
            }

            .cardCont {
                display: inline-block;
                white-space: normal;
            }
        }

        .cardCont {
            position: relative;
            width: 320px;
            height: 400px;
            margin-right: var(--margin-gap);
        }
    }
}


@media screen and (max-width: 1220px) {
    .swiperZone.offset {
        margin-left: 10px;
    }

    .inlineCards {
        .offset {
            width: 10px;
        }
    }
}

@media screen and (max-width: 600px) {
    .moduloCarousel {
        .controls {
            display: none;
        }

        .cardsZone {
            .swiperZone.offset {
                margin-left: 20px;
            }

            .inlineCards {
                .offset {
                    width: 20px;
                }
            }
        }
    }
}
</style>