export const useSocial = () => {

    const { api } = useRuntimeConfig().public

    const getJuicerPosts = async (id: string) => {
        return $fetch(`${api}social/${id}`)
    }

    return {
        getJuicerPosts
    }
}